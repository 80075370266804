import { Component } from '@angular/core'

/** Failsafe error component that displays contact information. */
@Component({
    selector: 'app-error',
    standalone: true,
    imports: [],
    template: ` <div class="col-12">
        <div class="container">
            <div class="d-flex justify-content-center align-items-center vh-100">
                <div class="d-flex flex-column justify-content-center">
                    <!-- Title -->
                    <h2 class="text-center mb-5">Oeps! Er is iets misgegaan. Probeer het later opnieuw.</h2>

                    <!-- Contact -->
                    <div class="p-3">
                        <h5>Contactgegevens</h5>
                        <div><span> Email: </span>&nbsp;<a href="mailto:info@hollandmovers.nl"> info&#64;hollandmovers.nl </a></div>
                        <div><span> Telefoon: </span>&nbsp;<a href="tel:020 260 2608">020 260 2608</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>`,
})
export class ErrorComponent {}
