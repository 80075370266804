import { enableProdMode, LOCALE_ID, ErrorHandler, importProvidersFrom, InjectionToken } from '@angular/core'
import localeNL from '@angular/common/locales/nl'
import { environment } from './environments/environment'
import { AppComponent } from './app/app.component'
import { ToastrModule } from 'ngx-toastr'
import { FullCalendarModule } from '@fullcalendar/angular'
import { LoginComponent } from 'app/auth/login/login.component'
import { InitComponent } from './app/auth/init/init.component'
import { provideRouter, Routes } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import { HttpConfigInterceptor } from './app/_services/interceptor.service'
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClientJsonpModule } from '@angular/common/http'
import * as Sentry from '@sentry/angular-ivy'
import { registerLocaleData } from '@angular/common'
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login'
import { ReactiveFormsModule } from '@angular/forms'
import {} from '@pqina/angular-pintura'

import { registerPlugin } from 'ngx-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import * as FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsDatepickerConfig, BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker'
import { nlLocale } from 'ngx-bootstrap/locale'
import { setTheme } from 'ngx-bootstrap/utils'
import { ModalModule } from 'ngx-bootstrap/modal'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { SharedFontAwesomeModule } from 'app/shared/fontawesome/fontawesome.module'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { ErrorComponent } from 'app/error/error.component'

// the second parameter 'nl' is optional
registerLocaleData(localeNL, 'nl')

registerPlugin(
    (FilePondPluginImageEditor as { default: unknown }).default,
    FilePondPluginFilePoster,
    FilePondPluginImageValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize
)

setTheme('bs4')
defineLocale('nl', nlLocale)

export function getDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: 'ddd DD/MM/YYYY',
        showWeekNumbers: false,
        showTodayButton: true,
        todayButtonLabel: 'Vandaag',
        locale: 'nl',
        customTodayClass: 'bs-datepicker-config-today',
        selectFromOtherMonth: true,
        adaptivePosition: true,
    })
}

const host = window.location.host
const s = {
    project: environment.package.name,
    version: environment.package.version,
    env: environment.production ? 'production' : 'develop',
    location: host.endsWith('localhost:4200') ? 'local' : 'remote',
}
if (host.startsWith('develop.')) s.env = 'develop'
Sentry.init({
    dsn: environment.sentry.dsn[s.project],
    environment: s.env,
    ignoreErrors: ['Non-Error exception captured'],
})

const ROUTES: Routes = [
    {
        path: 'web',
        loadChildren: () => import('./app/web/web.routes').then((m) => m.WEB_ROUTES),
    },
    {
        path: 'mobile',
        loadChildren: () => import('./app/mobile/mobile.routes').then((m) => m.MOBILE_ROUTES),
    },
    {
        path: 'public',
        loadChildren: () => import('./app/public/public.routes').then((m) => m.PUBLIC_ROUTES),
    },
    {
        path: 'statistics',
        loadChildren: () => import('./app/statistics/statistics.routes').then((m) => m.STATISTICS_ROUTES),
    },
    {
        path: 'settings',
        loadChildren: () => import('./app/settings/settings.routes').then((m) => m.SETTINGS_ROUTES),
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    { path: 'init/:email/:recover', component: InitComponent },
    { path: '**', component: LoginComponent },
]

if (environment.production) {
    enableProdMode()
}

export const BACKEND_URL = new InjectionToken<string>('backendUrl')

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            HttpClientJsonpModule,
            ReactiveFormsModule,
            FullCalendarModule,
            ModalModule.forRoot(),
            BsDropdownModule.forRoot(),
            BsDatepickerModule.forRoot(),
            TooltipModule.forRoot(),
            TabsModule.forRoot(),
            CollapseModule.forRoot(),
            AccordionModule.forRoot(),
            SharedFontAwesomeModule,
            ToastrModule.forRoot({
                timeOut: 3000,
                progressBar: true,
                maxOpened: 3,
                positionClass: 'toast-top-center',
                preventDuplicates: true,
            })
        ),
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: getDatepickerConfig },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('765000549371-oc0vc8qb5tsmaame19qvhsatfnoqnf4u.apps.googleusercontent.com', {
                            oneTapEnabled: false,
                        }),
                    },
                ],
                onError: (err) => {
                    console.error(err)
                },
            } as SocialAuthServiceConfig,
        },
        { provide: BACKEND_URL, useValue: environment.backendUrl },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                // showDialog: true,
            }),
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(ROUTES),
    ],
}).catch((err) => console.error(err))
