import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable({ providedIn: 'root' })
export class PublicService {
    constructor(private requestService: RequestService) {}

    user() {
        const url = `/public/auth`
        return this.requestService.get(url)
    }

    email(loginData) {
        const url = '/public/auth/email'
        const data = loginData
        return this.requestService.post(url, data)
    }

    login(loginData) {
        const url = '/public/auth/login'
        const data = loginData
        return this.requestService.post(url, data)
    }

    loginGoogle(loginData) {
        const url = '/public/auth/login-google'
        const data = loginData
        return this.requestService.post(url, data)
    }

    forgot(loginData) {
        const url = '/public/auth/forgot'
        const data = loginData
        return this.requestService.post(url, data)
    }

    recoverLogin(email, recover) {
        const url = '/public/auth/recover-login'
        const data = { email, recover }
        return this.requestService.post(url, data)
    }

    recoverSetPassword(email, password, recover) {
        const url = '/public/auth/recover-set-password'
        const data = { email, password, recover }
        return this.requestService.post(url, data)
    }

    getOrder(/** Encrypted ID */ orderId: string) {
        const url = `/public/order/${orderId}`
        return this.requestService.get(url)
    }

    getOrderwork(/** Encrypted ID */ orderworkId: string) {
        const url = `/public/orderwork/${orderworkId}`
        return this.requestService.get(url)
    }

    bookedOrderwork(/** Encrypted ID */ orderworkId: string) {
        const url = `/public/orderwork/${orderworkId}/booked`
        return this.requestService.patch(url, {})
    }

    cancellationOrderwork(/** Encrypted ID */ orderworkId: string, cancelComment: string) {
        const url = `/public/orderwork/${orderworkId}/cancellation`
        const data = { cancelComment }
        return this.requestService.patch(url, data)
    }

    estimateOrderwork(/** Encrypted ID */ orderworkId: string) {
        const url = `/public/orderwork/${orderworkId}/estimate`
        return this.requestService.patch(url, {})
    }

    mailOrderworkInvoice(/** Encrypted ID */ orderworkId: string) {
        const url = `/public/orderwork/${orderworkId}/mail-invoice`
        return this.requestService.get(url)
    }

    getCustomer(/** Encrypted ID */ customerId: string) {
        const url = `/public/customer/${customerId}`
        return this.requestService.get(url)
    }

    getCustomerSettings(/** Regular ID */ customerId: string) {
        const url = `/public/customer/settings/${customerId}`
        return this.requestService.get(url)
    }

    saveCustomerSettings(customer) {
        const url = `/public/customer/settings/${customer._id}`
        const data = { customer }
        return this.requestService.post(url, data)
    }

    doAction(actionType: string, action: string) {
        const url = `/public/action/${actionType}/${action}`
        return this.requestService.get(url)
    }

    /** Get timeslots from start of current week */
    getTimeslotsForOrderwork(/** Regular ID */ orderworkId: string) {
        const url = `/public/timeslot/orderwork/${orderworkId}`
        return this.requestService.get(url)
    }

    getViewingOrderwork(/** Encrypted ID */ orderworkId: string) {
        const url = `/public/viewing/orderwork/${orderworkId}`
        return this.requestService.get(url)
    }

    getViewingOrderworkTimeslot(/** Encrypted ID */ orderworkId: string) {
        const url = `/public/viewing/orderwork/${orderworkId}/timeslot`
        return this.requestService.get(url)
    }

    /** Update viewing orderwork with new state and start time and book timeslot */
    submitViewingOrderwork(/** Regular ID */ orderworkId: string, /** Regular ID */ timeslotId: string) {
        const url = `/public/viewing/orderwork/submit/${orderworkId}/${timeslotId}`
        const data = {}
        return this.requestService.patch(url, data)
    }
}
